import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { NotificationService } from '../notification/notification.service';
import { ErrorsService } from '../services/error/errors.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorCatchingInterceptor implements HttpInterceptor {
  private errorService: ErrorsService = inject(ErrorsService);
  private notificationService: NotificationService =
    inject(NotificationService);

  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('error catching', error);
        if (!(error.error instanceof ErrorEvent)) {
          this.errorService.verifyLocale(error.error).then((msg) => {
            if (msg?.title || msg?.message)
              this.errorMessage(msg?.title!, msg?.message!);
          });
        }
        return throwError(() => error);
      })
    );
  }

  /**
   * Exibir mensagem de erro conforme o retorno da API
   * @param title string - título da mensagem
   * @param message string - mensagem de erro
   */
  errorMessage(title: string, message: string) {
    this.notificationService.addMessageToService({
      severity: 'error',
      summary: title,
      detail: message,
    });
  }
}
