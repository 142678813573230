import { Injectable } from '@angular/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

/**
 * @description Handler para avisar quando uma tradução estiver faltando
 * @author Rafael Martins
 * @date 06/01/2025
 * @class CustomMissingTranslationHandler
 * @implements {MissingTranslationHandler}
 */
@Injectable()
export class CustomMissingTranslationHandler
  implements MissingTranslationHandler
{
  isDevMode: boolean = false;

  constructor() {
    if (environment.production) this.isDevMode = false;
    else this.isDevMode = true;
  }

  handle(params: MissingTranslationHandlerParams) {
    if (this.isDevMode === true) {
      alert(
        `Tradução faltando:\n- Key: ${
          params.key?.toUpperCase() || 'N/A'
        }\n- Idioma: ${
          params.translateService?.currentLang?.toUpperCase() || 'N/A'
        }
        `
      );

      console.group(`Tradução faltando:`);
      console.log(`- Key: ${params.key?.toUpperCase() || 'N/A'}`);
      console.log(
        `- Idioma: ${
          params.translateService?.currentLang?.toUpperCase() || 'N/A'
        }`
      );
      console.group('- Tracer completo:');
      console.log(params);
      console.groupEnd();
      console.groupEnd();

      return `🔴 ${params.key?.toUpperCase() || 'UNKNOWN_KEY'} 🔴`;
    } else {
      console.group(`Tradução faltando:`);
      console.log(`- Key: ${params.key?.toUpperCase() || 'N/A'}`);
      console.log(
        `- Idioma: ${
          params.translateService?.currentLang?.toUpperCase() || 'N/A'
        }`
      );
      console.groupEnd();

      return params.key?.toUpperCase() || 'UNKNOWN_KEY';
    }
  }
}
