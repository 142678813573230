import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CompaniesState } from '../../models/company-reducer/CompaniesState';
import { ProfileCompanyState } from '../../models/company-reducer/ProfileCompanyState';
import { WebMenuItemsModel } from '../../models/menu/WebMenuItemsModel';
import { ChangePasswordModel } from '../../models/password/ChangePasswordModel';
import { ProfileModel } from '../../models/profile/ProfileModel';
import { addCompaniesDetails } from '../../store/company.actions';
import {
  addFavoriteItem,
  removeFavoriteItem,
  setMenuFavorites,
  setMenuPermissions,
  setOthersAccess,
  setUsername,
  setWelcomeTuor,
  setWritePermissions,
} from '../../store/menu.actions';
import { IMenuState } from '../../store/menu.state';
import { LanguageService } from '../language/language.service';
import { MenuService } from '../menu/menu.service';
import { TenantsService } from '../tenant/tenants.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileApiService {
  private http: HttpClient = inject(HttpClient);
  private langService: LanguageService = inject(LanguageService);
  private router: Router = inject(Router);
  private tenant: TenantsService = inject(TenantsService);
  private store: Store<{ menu: IMenuState }> = inject(
    Store<{ menu: IMenuState }>
  );
  private menuService: MenuService = inject(MenuService);

  apiProfile: string = '';
  dados: WebMenuItemsModel[] = [];
  menuPermissions$ = new Observable<IMenuState>();

  private refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor() {
    this.apiProfile = `${environment.apiUrl}v1/tenants/user-profile`;
  }

  /**
   * Busca os dados do usuário logado e atualiza o store com as permissões do usuário, favoritos, outros acessos e nome do usuário
   * @returns Promise<any> com os dados do usuário divididos em response (tudo), favorites (favoritos) e menus (todos itens de menu e permissões)
   */
  getProfile(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.get(this.apiProfile).subscribe({
        next: (response: any) => {
          let tour = response.showWelcomeTour;
          localStorage.setItem('welcomeTourOpen', tour);

          this.store.dispatch(
            setMenuPermissions({ permissions: response.webMenuItems })
          );

          this.store.dispatch(
            setMenuFavorites({ favorites: response.favoritesMenuItem })
          );

          this.store.dispatch(
            setOthersAccess({
              othersAccess: response.webMenuItems,
              blacklist: this.menuService.getMenuBlacklist(),
            })
          );

          this.store.dispatch(
            setWritePermissions({ onlyWritePermissions: response.webMenuItems })
          );

          this.store.dispatch(setUsername({ username: response.name }));

          this.store.dispatch(
            setWelcomeTuor({ showWelcomeTour: response.showWelcomeTour })
          );

          // Atribuiçao de valores ao store de empresas
          this.pushCompanyStore(response);

          // Atribuição de valores ao objeto de retorno
          const result = {
            response,
            favorites: response.favoritesMenuItem,
            menus: response.webMenuItems,
          };

          this.langService.setInitialLang(response);
          resolve(result);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  /**
   * Informar dados para a store de empresas
   * @param response
   */
  pushCompanyStore(response: any) {
    this.store.dispatch(
      addCompaniesDetails({
        profileCompany: this.transformProfileToProfileState(response),
      })
    );
  }

  /**
   * Edita dados do perfil do usuário logado
   * @param data dados a serem editados
   */
  editProfile(data: any) {
    return this.http.put(this.apiProfile, data);
  }

  /**
   * Alterar senha do usuário logado
   * @param data ChangePasswordModel com a senha atual e a nova senha
   */
  editPassword(data: ChangePasswordModel) {
    return this.http.patch(`${this.apiProfile}/change-password`, data);
  }

  /**
   * Salvar menu como favorito e atualizar o store com o novo favorito
   * @param menuItemCode string com o código do menu
   * @returns
   */
  saveFavoriteMenu(menuItemCode: string) {
    this.store.dispatch(addFavoriteItem({ item: menuItemCode }));
    return this.http.put(`${this.apiProfile}/favorite/${menuItemCode}`, {});
  }

  /**
   * Deletar menu dos favoritos e atualizar o store com o novo array de favoritos
   * @param menuItemCode string com o código do menu
   */
  deleteFavoriteMenu(menuItemCode: string) {
    this.store.dispatch(removeFavoriteItem({ item: menuItemCode }));
    return this.http.delete(`${this.apiProfile}/favorite/${menuItemCode}`);
  }

  /**
   * Marcar o welcome tour como completo (feito)
   */
  completeWelcomeTour() {
    return this.http.patch(`${this.apiProfile}/show-welcome-tour`, {});
  }

  getRefresh() {
    return this.refresh.asObservable();
  }

  setRefresh(value: boolean) {
    this.refresh.next(value);
  }

  /**
   * Atualiza o array de permissões do usuário no store e retorna o array atualizado
   * @returns array de permissões do usuário (WebMenuItemsModel[])
   */
  getPermission(): Promise<WebMenuItemsModel[]> {
    return new Promise<WebMenuItemsModel[]>((resolve, reject) => {
      this.store.select('menu').subscribe({
        next: (data) => {
          const menus = data.permissions;
          resolve(menus);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  /**
   * Verifica se o usuário tem permissão para acessar o menu indicado
   * @param menu string com o código do menu
   * @param permission string com o código da permissão
   * @returns boolean
   */
  hasPermission(menu: string, permission: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let permissions: any;
      this.menuPermissions$ = this.store.select('menu');

      this.menuPermissions$
        .subscribe((data) => {
          let retorno = data.permissions;
          if (retorno === undefined || retorno.length === 0) {
            this.getProfile().then((result: any) => {
              this.menuPermissions$ = this.store.select('menu');
              resolve(this.checkPermission(result.menus, menu, permission));
            });
          } else {
            permissions = retorno;
            resolve(this.checkPermission(permissions, menu, permission));
          }
        })
        .unsubscribe();
    });
  }

  /**
   * Verifica de fato se o menu tem a permissão indicada comparando com o array de permissões do usuário
   * @param permissions array de permissões do usuário
   * @param menu string com o código do menu
   * @param permission string com o código da permissão
   * @returns boolean
   */
  private checkPermission(
    permissions: WebMenuItemsModel[],
    menu: string,
    permission: string
  ): boolean {
    const menuPermission = permissions.find((x) => x.codeName === menu);
    if (menuPermission) {
      const aux = menuPermission.scopes?.find((x) => x === permission);
      return !!aux;
    } else {
      return false;
    }
  }

  /**
   * Transformar os dados do perfil do usuário para o formato esperado na store de empresas
   * @param {ProfileModel} profile
   * @returns ProfileCompanyState
   */
  private transformProfileToProfileState(
    profile: ProfileModel
  ): ProfileCompanyState {
    let defaultCompany: CompaniesState | null = null;
    let othersCompanies: CompaniesState[] | null = [];
    let allCompanies: CompaniesState[] | null = [];
    let selectedCompany: CompaniesState | null = null;

    if (profile.defaultCompany) {
      let companyAux: CompaniesState = {
        id: profile?.defaultCompany?.id!,
        type: profile?.defaultCompany?.person?.type!,
        name: `${profile?.defaultCompany?.person?.personName!} - ${
          profile?.defaultCompany?.person?.mainDocument?.documentValue
        }`,
        active: profile?.active!,
        mainDocument:
          profile?.defaultCompany?.person?.mainDocument?.documentValue,
      };

      defaultCompany = companyAux;
      selectedCompany = companyAux;
      allCompanies?.push(companyAux);
    }

    if (profile.otherCompanies) {
      if (profile?.otherCompanies?.length! > 0) {
        profile?.otherCompanies?.forEach((company) => {
          if (company) {
            if (company?.active!) {
              let companyAux: CompaniesState = {
                id: company?.id!,
                type: company?.person?.type!,
                name: `${company?.person?.personName!} - ${
                  company?.person?.mainDocument?.documentValue
                }`,
                active: company?.active!,
                mainDocument: company?.person?.mainDocument?.documentValue,
              };

              othersCompanies?.push(companyAux);
              allCompanies?.push(companyAux);
            }
          }
        });
      }
    }

    return {
      defaultCompany,
      othersCompanies,
      allCompanies,
      selectedCompany,
    };
  }
}
