import { createReducer, on } from '@ngrx/store';
import { ScopeItem } from '../enums/ScopeItem';
import {
  addFavoriteItem,
  clearMenu,
  removeFavoriteItem,
  setMenuFavorites,
  setMenuPermissions,
  setOthersAccess,
  setUsername,
  setWelcomeTuor,
  setWritePermissions,
  updateShowWelcomeTour,
} from './menu.actions';

export interface IMenuState {
  permissions: any[];
  favorites: any[];
  username: string;
  othersAccess: any[];
  showWelcomeTour: boolean;
  onlyWritePermissions: any[];
}

export const initialState: IMenuState = {
  permissions: [],
  favorites: [],
  username: '',
  othersAccess: [],
  showWelcomeTour: false,
  onlyWritePermissions: [],
};

export const menuReducer = createReducer(
  initialState,
  on(setMenuPermissions, (state, { permissions }) => ({
    ...state,
    permissions,
  })),

  on(setMenuFavorites, (state, { favorites }) => ({
    ...state,
    favorites,
  })),

  on(setUsername, (state, { username }) => ({
    ...state,
    username,
  })),

  on(setOthersAccess, (state, { othersAccess, blacklist }) => {
    const othersItems = othersAccess.filter(
      (item) => !blacklist.includes(item.codeName)
    );

    if (state.favorites.length > 0) {
      state.favorites.forEach((favItem) => {
        const index = othersItems.findIndex(
          (accessItem) => favItem.codeName === accessItem.codeName
        );
        if (index !== -1) {
          othersItems.splice(index, 1);
        }
      });
    }

    return {
      ...state,
      othersAccess: othersItems,
    };
  }),

  on(setWelcomeTuor, (state, { showWelcomeTour }) => ({
    ...state,
    showWelcomeTour,
  })),

  on(addFavoriteItem, (state, { item }) => {
    const otherItems = [...state.othersAccess];
    const itemFound = state.permissions.find(
      (menuItem) => menuItem.codeName === item
    );
    const indexFound = otherItems.findIndex(
      (accessItem) => accessItem.codeName === itemFound.codeName
    );
    if (indexFound !== -1) {
      otherItems.splice(indexFound, 1);
    }

    return {
      ...state,
      favorites: [...state.favorites, itemFound],
      othersAccess: otherItems,
    };
  }),

  on(removeFavoriteItem, (state, { item }) => {
    const favoriteItems = [...state.favorites];
    const itemFound = state.permissions.find(
      (menuItem) => menuItem.codeName === item
    );
    const indexFound = favoriteItems.findIndex(
      (favItem) => favItem.codeName === itemFound.codeName
    );
    if (indexFound !== -1) {
      favoriteItems.splice(indexFound, 1);
    }

    return {
      ...state,
      favorites: favoriteItems,
      othersAccess: [...state.othersAccess, itemFound],
    };
  }),
  on(clearMenu, () => initialState),

  on(updateShowWelcomeTour, (state, { showWelcomeTour }) => ({
    ...state,
    showWelcomeTour,
  })),

  on(setWritePermissions, (state, { onlyWritePermissions }) => {
    let onlyWrite: any[] = [];
    let aux = onlyWritePermissions;
    aux.forEach((item) => {
      if (item.scopes?.find((x: any) => x === ScopeItem.WRITE)) {
        onlyWrite.push(item.codeName);
      }
    });

    return { ...state, onlyWritePermissions: onlyWrite };
  })
);
